































import Vue from "vue";
import { mapActions } from "vuex";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { DateTime } from "luxon";
import path from "path";
import store from "store2";

import { createMemberApplication, getYearMembershipFee } from "@/utils/backend";
import { displayError } from "@/utils/message";
import { storage } from "@/plugins/firebase";

import MemberApplicationForm from "@/components/member/ApplicationForm.vue";
import { MembershipApplicationForm } from "@/types/MembershipApplicationForm";
import MemberApplicationFormRegion from "@/components/member/ApplicationFormRegion.vue";

declare module "vue/types/vue" {
  interface Vue {
    showLoading: () => void;
    hideLoading: () => void;
    displayMessage: (string) => void;
  }
}

const PAY_BY_CARD = "PAY_BY_CARD";
const PAY_BY_BANK_TRANSFER = "PAY_BY_BANK_TRANSFER";
const PAY_OFFLINE = "PAY_OFFLINE";

interface Item {
  price: string;
  quantity: number;
}

export default Vue.extend({
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.prefillPreviousForm();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.prefillPreviousForm();
    next();
  },
  components: {
    MemberApplicationForm,
    MemberApplicationFormRegion,
    StripeCheckout,
  },
  computed: {
    lineItems(): Array<Item> {
      return [this.item];
    },
  },
  name: "MemberApplication",
  data: () => {
    return {
      email: "",
      fields: new Array<any>(),
      modal: false,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      loading: false,
      item: {
        price: process.env.VUE_APP_PRICE_ID,
        quantity: 1,
      } as Item,
      successURL: `https://${location.host}/member-application/success`,
      cancelURL: `https://${location.host}/member-application/failed`,
      applicationId: null,
      processing: false,
      PAY_BY_CARD,
      PAY_BY_BANK_TRANSFER,
      PAY_OFFLINE,
      payment_method: PAY_BY_CARD,
    };
  },
  methods: {
    ...mapActions(["showLoading", "hideLoading", "displayMessage"]),
    async confirmApplication() {
      if (this.processing) return;
      this.processing = true;
      try {
        this.showLoading();

        const file: File = (this.$refs.form as any).getFile();

        const formParams: MembershipApplicationForm = await (
          this.$refs.form as any
        ).getParams();

        store("membershipForm", formParams);

        let imagePath = (this.$refs.form as any).getRemoteImagePath();

        if (file) {
          const fileRef = ref(
            storage,
            `profile_images/${DateTime.now().toMillis()}${path.extname(
              file.name
            )}`
          );
          const snapshot = await uploadBytes(fileRef, file);
          imagePath = await getDownloadURL(fileRef);
        }

        const applicationParams = {
          ...formParams,
          payment_method: this.payment_method,
        };

        if (imagePath) {
          applicationParams["profile_image"] = imagePath;
          store("membershipImage", imagePath);
        } else {
          store.remove("membershipImage");
        }

        const application = await createMemberApplication(applicationParams);
        this.applicationId = application.id;

        const { year_membership_fee_id } = application;
        if (year_membership_fee_id) {
          const year_membership_fee = await getYearMembershipFee(
            year_membership_fee_id
          );
          this.item = {
            ...this.item,
            price: year_membership_fee.stripe_product_id,
          };
        }

        this.email = formParams.email;

        this.processing = false;

        if (this.payment_method === PAY_BY_CARD) {
          if (
            this.$refs.checkoutRef &&
            (this.$refs.checkoutRef as any).redirectToCheckout
          ) {
            await (this.$refs.checkoutRef as any)
              .redirectToCheckout()
              .catch((err) => {
                console.log(err);
                throw err.message;
              });
            this.hideLoading();
          }
        } else if (this.payment_method === PAY_BY_BANK_TRANSFER) {
          this.hideLoading();
          this.$router.replace({
            name: "Waiting Member Application",
          });
        } else {
          // PAY_OFFLINE
          this.hideLoading();
          this.$router.replace({
            name: "Waiting Offline",
          });
        }
      } catch (err) {
        this.processing = false;
        this.hideLoading();

        if (err === "FORM_INVALID") {
          this.displayMessage(displayError(err));
        } else if (err && !err.error) {
          this.displayMessage(err);
        } else {
          this.displayMessage(displayError(err?.error.code));
        }

        console.error(err);
      }
    },
    prefillPreviousForm() {
      const form = store("membershipForm");
      if (form) {
        (this.$refs.form as any).setItem(form);
        const imagePath = store("membershipImage");
        if (imagePath) {
          (this.$refs.form as any).setImage(imagePath);
        }
      }
    },
  },
});
